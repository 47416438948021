<template>
  <a-card class="container">
    <a-form
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      :colon="false"
      @submit="handleSubmit"
      :form="form"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="项目名称">
            <a-input
              :disabled="true"
              v-decorator="['name', 
                  { 
                                initialValue: detail.projectName,
                    rules: [{ required: true, message: '请输入' }] 
                    },]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="责任部门">
            <a-input
              :disabled="true"
              v-decorator="['chargeDeptName', 
                  { 
                                initialValue: detail.chargeDeptName,
                    rules: [{ required: true, message: '请输入' }] },]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="问题类型">
            <a-select
              style="width: 100%"
              v-decorator="[
                  'type',
                  { 
                    initialValue: detail.type,
                    rules: [{ required: true, message: '请选择！' }] },
                ]"
            >
              <a-select-option
                v-for="item in qualityProblemType"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="问题描述">
            <a-textarea
              v-decorator="[
                  'description',
                  { 
                    initialValue: detail.description,

                    rules: [{ required: true, message: '请输入！' }] },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="整改要求">
            <a-textarea
              v-decorator="[
                  'demand',
                  { 
                    initialValue: detail.demand,
                    rules: [{ required: true, message: '请输入！' }] },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="整改措施" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <a-textarea
              v-decorator="[
                  'measure',
                  { 
                    initialValue: detail.measure,
                    rules: [{ required: true, message: '请输入！' }] },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="问题照片" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <Images :list="images" />
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="整改后照片" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <Images :list="images2" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="整改情况">
            <a-radio-group
              v-decorator="[
                  'confirmStatus',
                  { 
                    initialValue: detail.confirmStatus,
                    rules: [{ required: true, message: '请选择！' }] },
                ]"
            >
              <a-radio
                v-for="item in [{name: '已完成',value: 1,}, {name: '未完成', value: 0}]"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="footer center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button htmlType="submit" type="primary" :loading="saveLoading">确认修改</a-button>
        </a-space>
      </div>
    </a-form>
  </a-card>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import { mapGetters } from "vuex";

import Images from "../components/images.vue";

function fetchDetail(params) {
  return request({
    url: "/office-service/quality/inspect/problem/queryDetail/" + params.id
  });
}

function modify(data) {
  return request({
    url: "/office-service/quality/inspect/problem/modify",
    method: "post",
    data
  });
}

export default {
  mixins: [watermark],

  components: {
    Images
  },

  data() {
    return {
      detail: {},
      form: this.$form.createForm(this),
      images: [],
      images2: [],

      saveLoading: false
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    qualityProblemType() {
      return this.findDataDict("qualityProblemType");
    }
  },

  mounted() {
    const hide = this.$message.loading("加载中...", 0);
    fetchDetail({
      id: this.$route.query.id
    })
      .then(res => {
        this.detail = res || {};

        if (typeof res.problemPicture === "string" || res.problemPicture) {
          this.images = res.problemPicture.split(",");
        }

        if (typeof res.rectifyPicture === "string" || res.rectifyPicture) {
          this.images2 = res.rectifyPicture.split(",");
        }
      })
      .finally(() => {
        hide();
      });
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log("values", values);
        if (!err) {
          this.save(values);
        }
      });
    },

    save(values) {
      this.saveLoading = true;

      modify({
        ...this.detail,
        ...values,
        problemPicture:
          this.images.length > 0 ? this.images.join(",") : undefined,
        rectifyPicture:
          this.images2.length > 0 ? this.images2.join(",") : undefined
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>


<style lang="less" scoped>
.container {
  padding-top: 24px;
}
.footer {
  padding: 80px 0;
}
</style>