var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"项目名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', 
                { 
                              initialValue: _vm.detail.projectName,
                  rules: [{ required: true, message: '请输入' }] 
                  },]),expression:"['name', \n                { \n                              initialValue: detail.projectName,\n                  rules: [{ required: true, message: '请输入' }] \n                  },]"}],attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"责任部门"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['chargeDeptName', 
                { 
                              initialValue: _vm.detail.chargeDeptName,
                  rules: [{ required: true, message: '请输入' }] },]),expression:"['chargeDeptName', \n                { \n                              initialValue: detail.chargeDeptName,\n                  rules: [{ required: true, message: '请输入' }] },]"}],attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"问题类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                { 
                  initialValue: _vm.detail.type,
                  rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'type',\n                { \n                  initialValue: detail.type,\n                  rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.qualityProblemType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"问题描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'description',
                { 
                  initialValue: _vm.detail.description,

                  rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'description',\n                { \n                  initialValue: detail.description,\n\n                  rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"整改要求"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'demand',
                { 
                  initialValue: _vm.detail.demand,
                  rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'demand',\n                { \n                  initialValue: detail.demand,\n                  rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"整改措施","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'measure',
                { 
                  initialValue: _vm.detail.measure,
                  rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'measure',\n                { \n                  initialValue: detail.measure,\n                  rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"问题照片","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('Images',{attrs:{"list":_vm.images}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"整改后照片","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('Images',{attrs:{"list":_vm.images2}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"整改情况"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'confirmStatus',
                { 
                  initialValue: _vm.detail.confirmStatus,
                  rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'confirmStatus',\n                { \n                  initialValue: detail.confirmStatus,\n                  rules: [{ required: true, message: '请选择！' }] },\n              ]"}]},_vm._l(([{name: '已完成',value: 1,}, {name: '未完成', value: 0}]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],1),_c('div',{staticClass:"footer center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading}},[_vm._v("确认修改")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }